<template>
    <div class="dashboard-reporting-history-view">
        <div class="dashboard-reporting-history-view__filters">
            <BaseMultiselect
                v-model="trackerFilter"
                :placeholder="$t('shared.asset')"
                :options="trackers"
                track-by="id"
                :custom-label="option => option.asset_details.name"
                block
                multiple
            />

            <BaseInput
                v-model="driverNameFilter"
                :placeholder="$t('placeholderDriverName')"
                block
                trim
            >
                <template #icon>
                    <PersonIcon width="16" height="16" />
                </template>
            </BaseInput>

            <DateInput
                v-model="dateFilter"
                :placeholder="$t('placeholderDate')"
                block
                range
            />

            <BaseMultiselect
                v-model="processedFilter"
                :placeholder="$t('placeholderProcessed')"
                :options="processedFilterOptions"
                label="name"
                :searchable="false"
                :preselect-first="true"
                block
            />
        </div>

        <div class="dashboard-reporting-history-view__content">
            <TachographHistoryTable
                :filter-date="dateFilter"
                :filter-driver="driverNameFilter"
                :filter-tracker="trackerFilter"
                :filter-processed="processedFilter"
                @clearFilter="
                    driverNameFilter = ''
                    trackerFilter = []
                    processedFilter = null
                "
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import DateInput from '../redesigned/DateInput'
import PersonIcon from '../icons/PersonIcon'
import TachographHistoryTable from '../TachographHistoryTable'

export default {
    name: 'DashboardTachographHistoryView',
    components: {
        BaseInput,
        BaseMultiselect,
        DateInput,
        PersonIcon,
        TachographHistoryTable,
    },
    data() {
        return {
            dateFilter: [
                moment()
                    .subtract(30, 'days')
                    .startOf('day')
                    .toDate(),
                moment()
                    .endOf('day')
                    .toDate(),
            ],
            driverNameFilter: '',
            trackerFilter: [],
            processedFilter: null,
            processedFilterOptions: [
                {
                    name: this.$t('notProcessed'),
                    value: false,
                    selectAll: false,
                },
                {
                    name: this.$t('processed'),
                    value: true,
                    selectAll: false,
                },
                {
                    name: this.$t('all'),
                    selectAll: true,
                },
            ],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
    },
}
</script>

<i18n>
{
    "en": {
        "placeholderDate": "Created at",
        "placeholderDriverName": "Driver name",
        "placeholderProcessed": "State",
        "processed": "Processed",
        "notProcessed": "Not Processed",
        "all": "All"
    },
    "de": {
        "placeholderDate": "Erstellt am",
        "placeholderDriverName": "Name des Fahrers",
        "placeholderProcessed": "Status",
        "processed": "Verarbeitet",
        "notProcessed": "Nicht verarbeitet",
        "all": "Alle"
    },
    "fr": {
        "placeholderDate": "Créé à",
        "placeholderDriverName": "Nom du conducteur",
        "placeholderProcessed": "Statut",
        "processed": "Traité",
        "notProcessed": "Pas traité",
        "all": "Tous"
    },
    "it": {
        "placeholderDate": "Creato a",
        "placeholderDriverName": "Nome del conducente",
        "placeholderProcessed": "Stato",
        "processed": "Trattare",
        "notProcessed": "Non Trattare",
        "all": "Tutti"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-reporting-history-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
            max-width: 200px;
        }

        .base-multiselect {
            max-width: 240px;
        }
    }

    &__content {
        display: flex;
        overflow-y: auto;

        & > * {
            width: 100%;
            overflow-x: auto;
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
