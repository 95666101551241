<template>
    <VDatepicker
        v-bind="$attrs"
        :disabled-dates="disabledDates"
        :format="datepickerFormat"
        :highlighted="highlightedDates"
        :language="datepickerLocale"
        :input-class="[
            'date-input__input',
            {
                'date-input__input--block': block,
                'date-input__input--disabled': $attrs.disabled,
            },
        ]"
        use-utc
        :value="range ? daterange[0] : value"
        wrapper-class="date-input__wrapper"
        v-on="listeners"
    >
        <div
            v-if="range && daterangeFormatted"
            slot="afterDateInput"
            class="t-truncated date-input__daterange"
        >
            {{ daterangeFormatted }}
        </div>
    </VDatepicker>
</template>

<script>
import moment from 'moment'
import VDatepicker from 'vuejs-datepicker'
import { de, en, fr, it } from 'vuejs-datepicker/dist/locale'

export default {
    name: 'DateInput',
    components: {
        VDatepicker,
    },
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Array, Date, String],
            default: null,
        },
    },
    data() {
        return {
            datepickerFormat: 'dd.MM.yyyy',
            daterange: [],
        }
    },
    computed: {
        datepickerLocale() {
            const locales = { de, en, fr, it }
            return locales[this.$i18n.locale] || en
        },
        daterangeFormatted() {
            const format = this.datepickerFormat.toUpperCase()
            return this.daterange.length
                ? `${moment(this.daterange[0]).format(format)} - ${
                      this.daterange[1]
                          ? moment(this.daterange[1]).format(format)
                          : '?'
                  }`
                : null
        },
        disabledDates() {
            return {
                to: !this.daterange[1] ? this.daterange[0] : null,
            }
        },
        highlightedDates() {
            return {
                from: this.daterange[0],
                to: this.daterange[1],
            }
        },
        listeners() {
            return this.range
                ? {
                      ...this.$listeners,
                      input: date => {
                          if (this.daterange.length >= 2) {
                              this.daterange = [date]
                          } else {
                              this.daterange.push(
                                  moment(date)
                                      .endOf('day')
                                      .toDate()
                              )
                          }

                          if (this.daterange.length === 1) {
                              this.$nextTick(() => {
                                  document.activeElement?.click?.()
                              })
                          } else if (this.daterange.length === 2) {
                              this.$emit('input', this.daterange)
                          }
                      },
                  }
                : this.$listeners
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.range) {
                    this.daterange = this.value
                }
            },
        },
    },
}
</script>

<style lang="scss">
.date-input {
    $self: &;

    &__daterange {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 12px;
        background-color: $color-gray-lighter-new;
        border-radius: 4px;
        line-height: 16px;
        font-size: 14px;
        color: $color-text-new;
        pointer-events: none;
    }

    &__input {
        padding: 12px !important;
        width: 100%;
        background-color: $color-gray-lighter-new !important;
        border: none !important;
        border-radius: 4px !important;
        line-height: 16px;
        font-size: 14px !important;
        font-family: 'IBM Plex Sans', sans-serif;
        color: $color-text-new;
        outline: none;

        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
        }

        &--block {
            padding: 10px 12px !important;
            background-color: #fff !important;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 8px !important;
            line-height: 20px;

            & + #{$self}__daterange {
                padding: 10px 12px;
                background-color: #fff;
                border-radius: 8px;
                line-height: 20px;
            }
        }

        &--disabled {
            opacity: 0.75;
        }
    }

    &__wrapper {
        width: 100%;

        .vdp-datepicker__calendar {
            margin-top: 6px;
            padding: 8px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 8px;
        }
    }
}
</style>
